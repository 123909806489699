import { useEffect, useState } from "react";

export const useKeyPress = (
  index,
  signalLists,
  handleKeyEvent,
  signalName,
  listElm,
  inputElement
) => {
  const [selectedIndex, setSelectedIndex] = useState(index);
  useEffect(() => {
    const downHandler = ({ key, target }) => {
      const name = target.id;
      if (key === "ArrowDown" && name === listElm) {
        setSelectedIndex(
          selectedIndex !== signalLists.length - 1 ? selectedIndex + 1 : 0
        );
        inputElement &&
          document
            .getElementById(
              signalName +
                "-" +
                (selectedIndex !== signalLists.length - 1
                  ? selectedIndex + 1
                  : 0)
            )
            .scrollIntoView({
              behavior: "smooth",
              block: "nearest",
              inline: "nearest",
            });
      }
      if (key === "Enter" && name === listElm) {
        handleKeyEvent(signalLists[selectedIndex]);
      }
    };

    const upHandler = ({ key, target }) => {
      const name = target.getAttribute("id");
      if (key === "ArrowUp" && name === listElm) {
        setSelectedIndex(
          selectedIndex !== 0 ? selectedIndex - 1 : signalLists.length - 1
        );
        inputElement &&
          document
            .getElementById(
              signalName +
                "-" +
                (selectedIndex !== 0
                  ? selectedIndex - 1
                  : signalLists.length - 1)
            )
            .scrollIntoView({
              behavior: "smooth",
              block: "nearest",
              inline: "nearest",
            });
      }
      if (key === "Enter" && name === listElm) {
        handleKeyEvent(signalLists[selectedIndex]);
      }
    };

    inputElement && inputElement.addEventListener("keydown", downHandler);
    inputElement && inputElement.addEventListener("keyup", upHandler);

    return () => {
      inputElement && inputElement.removeEventListener("keydown", downHandler);
      inputElement && inputElement.removeEventListener("keyup", upHandler);
    };
  }, [selectedIndex, handleKeyEvent, signalName, signalLists]);

  return { selectedIndex, setSelectedIndex };
};
