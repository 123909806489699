import React from "react";
import { useKeyPress } from "../../hooks/useKeyPress";
import ClickAwayListenerHoc from "../../components/shared/UI/ClickAwayListenerHoc";

const SignalSuggestions = (props) => {
  const { signalLists, idx, signalName, selectSignal, handleCloseSuggestions } =
    props;
  const handleKeyEvent = (list) => {
    selectSignal(list, idx, signalName);
  };
  const inputElement = document.getElementById(signalName + idx);
  const { selectedIndex, setSelectedIndex } = useKeyPress(
    -1,
    signalLists,
    handleKeyEvent,
    signalName,
    signalName + idx,
    inputElement
  );
  return (
    <ClickAwayListenerHoc
      elementToIgnore={signalName}
      key={`clickaway-${idx}`}
      nodeRef={inputElement}
      hideElement={() => {
        handleCloseSuggestions(signalName, idx);
        setSelectedIndex(-1);
      }}
    >
      <div className="list-container">
        <ul className="signal-suggestion-list" name="signal-options">
          {signalLists
            .sort(function (a, b) {
              return a.toLowerCase().localeCompare(b.toLowerCase());
            })
            .map((list, index) => {
              return (
                <li
                  onMouseEnter={() => setSelectedIndex(index)}
                  onMouseLeave={() => setSelectedIndex(-1)}
                  name={signalName}
                  key={signalName + index}
                  id={signalName + "-" + index}
                  className={index === selectedIndex ? "list-selected" : ""}
                  onClick={() => {
                    handleKeyEvent(list);
                  }}
                  tabIndex={"0"}
                >
                  {list}{" "}
                </li>
              );
            })}
        </ul>
      </div>
    </ClickAwayListenerHoc>
  );
};

export default SignalSuggestions;
